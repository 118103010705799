import { EContainerType } from 'models/container/enumeration'
import { TContainerCreateDTO } from 'models/forms/container/ContainerCreateDTO'
import { TContainerCreateForm } from 'models/forms/container/ContainerCreateForm'
import ContainerDefaultCreateDTO from 'models/forms/container/ContainerDefaultCreateDTO'
import ContainerPageCreateDTO from 'models/forms/container/ContainerPageCreateDTO'
import { IContainerPageCreateForm } from 'models/forms/container/ContainerPageCreateForm'
import ContainerTemplateCreateDTO from 'models/forms/container/ContainerTemplateCreateDTO'
import { IContainerTemplateCreateForm } from 'models/forms/container/ContainerTemplateCreateForm'
import ContainerVideoShowCreateDTO from 'models/forms/container/ContainerVideoShowCreateDTO'
import { IContainerVideoShowCreateForm } from 'models/forms/container/ContainerVideoShowCreateForm'

export const ContainerFormToDTO = (form: TContainerCreateForm): TContainerCreateDTO => {
  if (form.type === EContainerType.PAGE) {
    const pageForm = form as IContainerPageCreateForm
    return new ContainerPageCreateDTO(
      pageForm.name?.trim(),
      pageForm.publicTitle?.trim(),
      pageForm.alias,
      pageForm.aliasLink,
      pageForm.subType,
      pageForm.content
    )
  }

  if (form.type === EContainerType.VIDEO_SHOW) {
    const videoShowForm = form as IContainerVideoShowCreateForm
    return new ContainerVideoShowCreateDTO(
      videoShowForm.name?.trim(),
      videoShowForm.publicTitle?.trim(),
      videoShowForm.alias,
      videoShowForm.aliasLink,
      videoShowForm.content
    )
  }

  if (form.type === EContainerType.TEMPLATE) {
    const templateForm = form as IContainerTemplateCreateForm
    return new ContainerTemplateCreateDTO(
      templateForm.name?.trim(),
      templateForm.publicTitle?.trim(),
      templateForm.alias,
      templateForm.displayPriority,
      {
        contentTypes: templateForm.contentTypes,
        contentSources: templateForm.contentSources,
        contentInterests: templateForm.contentInterests,
        contentTags: templateForm.contentTags,
        contentGenres: templateForm.contentGenres,
      },
      templateForm.contentOwner
    )
  }

  return new ContainerDefaultCreateDTO(form.name?.trim(), form.publicTitle?.trim(), form.alias)
}
