import {
  EContentOriginSystem,
  EContentOwner,
  EContentSource,
  EContentState,
  ERecordType,
} from 'models/content/enumeration'
import { EFilterType } from 'models/filters/Enumeration'
import { TPfuFilterItem } from 'modules/filters-panel/filters-panel'

/**
 * To ADD filters add it in which tree you want it first as a branch or a leaf --> ./filters.ts
 *
 * Then ADD it in the array where all the filters are, for redux usage
 * Name MUST be the same to display it !!
 */

// Use for handle filter selection in redux
export const initialPfuFilters: TPfuFilterItem[] = [
  // RecordType
  {
    type: EFilterType.TYPE,
    name: ERecordType.PAGE_HOME,
    value: [ERecordType.PAGE_HOME, ERecordType.PAGE_CATCH_UP, ERecordType.CUSTOM_PAGE],
    isSelected: false,
  },
  { type: EFilterType.TYPE, name: ERecordType.GENRE, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.PAGE_EVENT, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.PAGE_GENRE, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.PAGE_SPECIAL, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.PAGE_THEME, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.PAGE, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.CHANNEL, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.COMBO, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.REGIONALIZED, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.HERO, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.LIST, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.MOSAIC, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.STANDARD, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.POSTER, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.FEED_SOURCE, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.FEED_PRIMARY_CATEGORY, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.FEED_REGIONALIZED, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.FEED_STANDARD, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.PERSONALIZED_FEED, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.STORY, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.VIDEO, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.VIDEO_STREAM, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.VIDEO_COMING_SOON, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.VIDEO_SEASON, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.VIDEO_PARENT_SHOW, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.VIDEO_PARENT_MOVIE, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.VIDEO_PARENT_DOCUMENTARY, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.VIDEO_PARENT_PERFORMANCE, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.VIDEO_PREVIEW, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.AUDIO, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.AUDIO_SEASON, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.AUDIO_SHOW, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.AUDIO_STREAM, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.RADIO_UNCUT, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.RADIO_SEGMENT, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.RADIO_TRAILER, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.RADIO_BONUS, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.PODCAST_EPISODE, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.AUDIO_UNKNOWN, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.EXTERNAL_LINK, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.SPONSOR, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.TEMPLATE, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.BOOK_VARIANT_PAPERBACK, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.BOOK_VARIANT_UNKNOWN, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.MENU, isSelected: false },
  { type: EFilterType.TYPE, name: ERecordType.CONTEST, isSelected: false },

  // Origin Systems
  { type: EFilterType.ORIGIN_SYSTEM, name: EContentOriginSystem.EMP, isSelected: false },
  { type: EFilterType.ORIGIN_SYSTEM, name: EContentOriginSystem.CUBE, isSelected: false },
  { type: EFilterType.ORIGIN_SYSTEM, name: EContentOriginSystem.OMNY, isSelected: false },
  { type: EFilterType.ORIGIN_SYSTEM, name: EContentOriginSystem.BRIGHTCOVE, isSelected: false },
  { type: EFilterType.ORIGIN_SYSTEM, name: EContentOriginSystem.PFU, isSelected: false },
  { type: EFilterType.ORIGIN_SYSTEM, name: EContentOriginSystem.SHOPIFY, isSelected: false },

  // Sources
  { type: EFilterType.SOURCE, name: EContentSource.JDM, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.JDQ, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.TVA, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.TVAN, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.TVAS, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.TVAS2, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.CDEP, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.QUBR, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.ADDIKTV, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.CASA, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.TEMOIN, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.PRISE2, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.PSS, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.BILLIE, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.SDC, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.SILO57, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.SB, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.CLINDOEIL, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.SEPTJOURS, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.QUB, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.QUBTV, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.VINGT_QUATRE_HEURES, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.VRAI, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.EVASION, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.LCN, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.ZESTE, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.TVAPLUS, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.QUBI, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.HUBLO, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.PORTEMONNAIE, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.BUREAUDENQUETE, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.EN5MINUTES, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.QUBL, isSelected: false },
  { type: EFilterType.SOURCE, name: EContentSource.CLUB_ILLICO, isSelected: false },

  // States
  { type: EFilterType.STATE, name: EContentState.ACTIVE, isSelected: false },
  { type: EFilterType.STATE, name: EContentState.ERROR, isSelected: false },
  { type: EFilterType.STATE, name: EContentState.INACTIVE, isSelected: false },
  { type: EFilterType.STATE, name: EContentState.INCOMPLETE, isSelected: false },

  // Owners
  { type: EFilterType.OWNER, name: EContentOwner.JDM, isSelected: false },
  { type: EFilterType.OWNER, name: EContentOwner.JDQ, isSelected: false },
  { type: EFilterType.OWNER, name: EContentOwner.INFO, isSelected: false },
  { type: EFilterType.OWNER, name: EContentOwner.LIVRE, isSelected: false },
  { type: EFilterType.OWNER, name: EContentOwner.RADIO, isSelected: false },
  { type: EFilterType.OWNER, name: EContentOwner.TVAN, isSelected: false },
  { type: EFilterType.OWNER, name: EContentOwner.TVAPLUS, isSelected: false },
  { type: EFilterType.OWNER, name: EContentOwner.TVAS, isSelected: false },
  { type: EFilterType.OWNER, name: EContentOwner.VRAI, isSelected: false },
  { type: EFilterType.OWNER, name: EContentOwner.CLUB_ILLICO, isSelected: false },

  // Linked Content
  { type: EFilterType.LINKED_CONTENT, name: EFilterType.LINKED_CONTENT, value: ['true'], isSelected: false },

  // Recommendable
  { type: EFilterType.RECOMMANDABLE, name: EFilterType.RECOMMANDABLE, value: ['true'], isSelected: false },

  // Expired
  { type: EFilterType.EXPIRED, name: EContentState.EXPIRED, isSelected: false },

  // Embargo
  { type: EFilterType.EMBARGO, name: EContentState.EMBARGO, isSelected: false },
]
