import { ECardType } from 'models/card/CardType'
import { TPfuCard } from 'models/card/GenericCard'
import { IContainer, ISlot, ITimeSlot, TBaseSlot, TSlotPosition } from 'models/container/container'
import { EConfigSource, EContainerSlotType, EDays, ESlotConfig, ESlotPosition } from 'models/container/enumeration'
import { IIdentifierPfuConfig, ISlotUpdate, TBaseConfigUpdate } from 'models/detail/container/ContainerUpdate'
import { TContainerCreateDTO } from 'models/forms/container/ContainerCreateDTO'
import { TContainerCreateForm } from 'models/forms/container/ContainerCreateForm'
import ContainerForm from 'models/forms/container/ContainerForm'
import ContainerRequestForm, {
  IActiveDays,
  IRecurrence,
  RecurrenceType,
} from 'models/forms/container/request/ContainerRequestForm'
import { formatDate, nextDayOfWeek } from 'utils/date'

import { ContainerFormToDTO } from './container/ContainerFormToDTOService'

export const AVAILABLE_REQUEST_COLORS = ['#4337BD', '#1A4DB0', '#631AB0', '#2C0058', '#072868']

export default class ContainerFormService {
  public static toContainer(containerForm: ContainerForm, updatedContainer: IContainer) {
    return {
      ...updatedContainer,
      type: containerForm.type,
      name: containerForm.name,
      publicTitle: containerForm.publicTitle,
      slots: this.baseSlotsToSlots(containerForm.slots, containerForm.positions),
    }
  }

  public static toNewContainer(creationContainerForm: TContainerCreateForm): TContainerCreateDTO {
    return ContainerFormToDTO(creationContainerForm)
  }

  public static positionIndexesBySlot(baseSlot: TBaseSlot, positions: TSlotPosition[] = []): number[] {
    return positions.reduce((indexes, position, index) => {
      if (position === baseSlot.name) {
        return [...indexes, index + 1]
      }

      return indexes
    }, [])
  }

  public static slotToBaseSlot(slot: ISlot): TBaseSlot {
    const slotCopy: ISlot = {
      ...slot,
    }

    delete slotCopy.position

    return slotCopy
  }

  public static slotsToUniqueSlots(slots: ISlot[] = []): TBaseSlot[] {
    return slots.reduce((acc, slot) => {
      if (acc.some((s) => s.name === slot.name)) {
        return acc
      }

      const baseSlot = this.slotToBaseSlot(slot)

      return [...acc, { ...baseSlot }]
    }, [])
  }

  public static slotsToPositions(slots: ISlot[] = []): TSlotPosition[] {
    function getSlotInPosition(index: number) {
      return slots.find((slot) => slot.position?.indexes.includes(index))
    }

    const allPositions = slots.map((slot) => slot.position.indexes).reduce((acc, curr) => [...acc, ...curr], [])
    const highestPosition = Math.max(...allPositions)

    return Array.from({ length: highestPosition }).map((_, index) => {
      const slot = getSlotInPosition(index + 1)

      return slot ? slot.name : null
    })
  }

  public static baseSlotsToSlots(slots: TBaseSlot[], positions: TSlotPosition[]): ISlot[] {
    const trimmedPositions = positions?.filter(Boolean)

    return trimmedPositions?.reduce((acc, slotName) => {
      const slot = slots.find((s) => s.name === slotName)

      if (acc.some((s) => s.name === slotName)) {
        return acc
      }

      const slotWithPositions = {
        ...slot,
        position: {
          type: ESlotPosition.FIXED,
          indexes: this.positionIndexesBySlot(slot, trimmedPositions),
        },
      }

      return [...acc, slotWithPositions]
    }, [] as ISlot[])
  }

  public static slotColors = (uniqueSlots: TBaseSlot[]) => {
    return uniqueSlots
      .filter((slot: TBaseSlot) => slot.config.type !== ESlotConfig.IDENTIFIER)
      .reduce<{ [slotName: string]: string }>((acc, slot, index) => {
        const color = AVAILABLE_REQUEST_COLORS[index % AVAILABLE_REQUEST_COLORS.length]
        return { ...acc, [slot.name]: color }
      }, {})
  }

  public static cardToSlot(data: TPfuCard): ISlotUpdate<IIdentifierPfuConfig> {
    return {
      name: `${data.name} ${Date.now()}`,
      type: data.cardType === ECardType.CONTAINER ? EContainerSlotType.CONTAINER : EContainerSlotType.CONTENT,
      config: {
        type: ESlotConfig.IDENTIFIER,
        identifier: data.id,
        source: EConfigSource.PFU,
      },
      position: undefined,
    }
  }

  public static daysToActiveTimeSlots({
    type,
    activeDays,
    startDateTime,
    endDateTime,
    startDateDay,
    endDateDay,
  }: IRecurrence): ITimeSlot[] {
    if (type === RecurrenceType.CONSECUTIVE) {
      return [
        {
          startBoundary: {
            dayOfWeek: startDateDay,
            timeOfDay: formatDate(startDateTime, 'HH:mm'),
          },
          endBoundary: {
            dayOfWeek: startDateTime > endDateTime ? nextDayOfWeek(endDateDay) : endDateDay,
            timeOfDay: formatDate(endDateTime, 'HH:mm'),
          },
        },
      ]
    }

    const daysActive = Object.keys(activeDays).filter((day: keyof IActiveDays) => activeDays[day]) as EDays[]
    return daysActive.map((day) => {
      return {
        startBoundary: {
          dayOfWeek: day,
          timeOfDay: formatDate(startDateTime, 'HH:mm'),
        },
        endBoundary: {
          dayOfWeek: startDateTime > endDateTime ? nextDayOfWeek(day) : day,
          timeOfDay: formatDate(endDateTime, 'HH:mm'),
        },
      }
    })
  }

  public static toConfig(requestForm: ContainerRequestForm): TBaseConfigUpdate {
    const dates = {
      activationStartDateTime: requestForm.activationStartDateTime,
      activationEndDateTime: requestForm.activationEndDateTime,
      recurrence: {
        recurrenceType: requestForm.requestRecurrence.type,
        activeTimeSlots: this.daysToActiveTimeSlots(requestForm.requestRecurrence),
      },
    }

    if (requestForm.identifier) {
      return {
        type: ESlotConfig.IDENTIFIER,
        identifier: requestForm.identifier,
        source: requestForm.source,
        ...dates,
      }
    }

    if (
      requestForm.containerConfiguration === ESlotConfig.RESUMEREADING ||
      (requestForm.containerConfiguration === ESlotConfig.PROGRESS && requestForm.requestCompletedItems === false)
    ) {
      return {
        type: ESlotConfig.PROGRESS,
        contentCategories: requestForm.contentCategories,
        contentSources: requestForm.contentSources,
        contentTypes: requestForm.contentTypes,
        contentOwners: requestForm.contentOwners,
        requestCompletedItems: requestForm.requestCompletedItems,
        ...dates,
      }
    }

    if (requestForm.containerConfiguration === ESlotConfig.PROGRESS && requestForm.requestCompletedItems !== false) {
      return {
        type: ESlotConfig.PROGRESS,
        contentCategories: requestForm.contentCategories,
        contentSources: requestForm.contentSources,
        contentTypes: requestForm.contentTypes,
        requestCompletedItems: requestForm.requestCompletedItems,
        ...dates,
      }
    }
    if (requestForm.containerConfiguration === ESlotConfig.STANDARD) {
      return {
        type: ESlotConfig.STANDARD,
        contentCategories: requestForm.contentCategories,
        contentSources: requestForm.contentSources,
        contentTags: requestForm.contentTags,
        contentQueryTypologies: requestForm.contentQueryTypologies,
        contentAdministrativeRegions: requestForm.contentAdministrativeRegions,
        audioShows: requestForm.audioShows,
        ...dates,
      }
    }

    if (requestForm.containerConfiguration === ESlotConfig.VISUAL_ENTERTAINMENT) {
      return {
        type: ESlotConfig.VISUAL_ENTERTAINMENT,
        contentSources: requestForm.contentSources,
        contentCategories: requestForm.contentCategories,
        contentTags: requestForm.contentTags,
        contentQueryTypologies: requestForm.contentQueryTypologies,
        shows: requestForm.shows,
        showSeasons: requestForm.showSeasons,
        genres: requestForm.genres,
        ...dates,
      }
    }

    if (requestForm.containerConfiguration === ESlotConfig.RECOMMENDATION_CAROUSEL) {
      return {
        type: ESlotConfig.RECOMMENDATION_CAROUSEL,
        ...dates,
      }
    }

    return {
      type: ESlotConfig.RECOMMENDATION,
      contentCategories: requestForm.contentCategories,
      contentSources: requestForm.contentSources,
      contentTags: requestForm.contentTags,
      contentQueryTypologies: requestForm.contentQueryTypologies,
      ...dates,
    }
  }
}
